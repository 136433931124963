<template>
  <div class="main">
    <TableMain
      tableTitle="Áreas de Trabajo"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Crear Área de Trabajo"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="['#', 'Nombre', 'Número de Empleados', 'Fecha de Creación']"
      :data="workAreas"
      :editOption="true"
      :deleteOption="true"
      @editAction="editEvent($event)"
      @deleteAction="deleteEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";

import { prettyDate } from "@/modules/tools";

export default {
  components: { Legal, TableMain },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Áreas de Trabajo / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    editEvent(_id) {
      this.$router.push({ name: "Work Area Edit", params: { _id: _id } });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("workArea", _id).then(workArea => {
        Swal.fire({
          title: "¿Eliminar Área de Trabajo?",
          text: "Se eliminará el área de trabajo: " + workArea.name,
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando área de trabajo",
              html:
                "Por favor espere mientras se elimina el área de trabajo</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            store.deleteData("optima", "workArea", _id).then(() => {
              Swal.fire({
                icon: "success",
                title: "Área de trabajo eliminado",
                text: "Todos los datos han sido sincronizados"
              }).then(() => {
                this.$router.go();
              });
            });
          }
        });
      });
    }
  },
  created() {
    const store = new Storage();
    store.getData("workArea").then(workData => {
      workData.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      workData.forEach(area => {
        if (area.branch === this.$route.params._id) {
          prettyDate(area.registrationDate).then(date => {
            store.getData("employee").then(employeeData => {
              let count = 0;
              employeeData.forEach(employee => {
                if (employee.workArea === area._id) {
                  count++;
                }
              });
              this.workAreas.push([area._id, area.name, count, date]);
            });
          });
        }
      });
    });
  },
  data() {
    return {
      workAreas: []
    };
  },
  name: "Work Area Branch"
};
</script>
